import React from "react";
import tw, { styled } from "twin.macro";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Header from "@shared/components/Header";
import { MAIN_COLOR, SITE } from "../config";
import AvatarPlaceholder from "../components/AvatarPlaceholder";
import _ from "lodash";

const Container = styled.div`
  ${tw`p-5`}
`;
const GridContainer = styled.div`
  ${tw`grid grid-cols-1 my-5 gap-5`}
`;
const GridItem = styled.div`
  ${tw`w-full mx-auto sm:mx-0 mb-5`}
`;
const ImageContainer = styled.div`
  ${tw`relative mb-5 max-w-[250px] mx-auto`}
`;
const Position = styled.div`
  ${tw`whitespace-nowrap text-[#EC1F27] text-xs  text-center`}
`;
const Name = styled.div`
  ${tw`text-sm mb-2.5 text-center`}
`;

const Body = styled.div`
  ${tw`prose max-w-full`}

  iframe {
    ${tw`w-full`}
  }
`;
const CoachContainer = styled.div`
  ${tw`p-5`}
`;

const CoachItem = styled.div`
  ${tw`mt-8 flex flex-col justify-center md:flex-row`}
`;
const AvatarContainer = styled.div`
  ${tw`w-full md:w-[25%]`}
`;
const ContentContainer = styled.div`
  ${tw`w-full md:w-[75%] md:px-4`}
`;
const CoachTitle = styled.h6`
  ${tw`my-2 text-[#535353]`}
`;

const CoachSubtitle = styled.h6`
  ${tw`my-2 text-[#535353] text-sm`}
`;
const CoachPage = ({
  data: {
    markdownRemark: {
      frontmatter: { seo, title, hero, tabs },
    },
    coachMds,
  },
  location,
}) => {
  return (
    <Layout
      seoTitle={seo?.title || title}
      seoDescription={seo?.description}
      location={location}
    >
      <Container>
        {title && (
          <Header
            title={title}
            heroData={hero}
            tabsData={tabs}
            color={MAIN_COLOR}
            site={SITE}
          />
        )}
        <GridContainer>
          {!_.isEmpty(coachMds.edges) && (
            <CoachContainer>
              {coachMds.edges
                .sort(
                  (a, b) => a.node.frontmatter.order - b.node.frontmatter.order
                )
                .map((coach) => {
                  const { id, html } = coach.node;
                  const { name, avatar, title, college, homeTown } =
                    coach.node.frontmatter;
                  const img = getImage(avatar);
                  return (
                    <CoachItem key={id}>
                      <AvatarContainer>
                        {img ? (
                          <GatsbyImage
                            image={img}
                            alt="avatar"
                            style={{ width: "100%", height: "100%" }}
                            imgStyle={{ width: "100%", height: "100%" }}
                            objectFit="cover"
                            objectPosition="50% 50%"
                          />
                        ) : (
                          <AvatarPlaceholder />
                        )}
                      </AvatarContainer>
                      <ContentContainer>
                        <CoachTitle>
                          {name} - {title}
                        </CoachTitle>
                        <CoachSubtitle>
                          {college} {homeTown}
                        </CoachSubtitle>
                        <div dangerouslySetInnerHTML={{ __html: html }} />
                      </ContentContainer>
                    </CoachItem>
                  );
                })}
            </CoachContainer>
          )}
          {/* {coachMds.edges.map((edge, idx) => {
            const img = getImage(edge.node.frontmatter.avatar);
            return (
              <GridItem key={idx}>
                <ImageContainer>
                  {img ? (
                    <GatsbyImage
                      image={img}
                      alt="coach image"
                      style={{ height: "100%" }}
                    />
                  ) : (
                    <AvatarPlaceholder />
                  )}
                </ImageContainer>
                <Name>
                  {edge.node.frontmatter.name}, {edge.node.frontmatter.college}
                </Name>
                <Position>{edge.node.frontmatter.position}</Position>
                <Body dangerouslySetInnerHTML={{ __html: edge.node.html }} />
              </GridItem>
            );
          })} */}
        </GridContainer>
      </Container>
    </Layout>
  );
};

export default CoachPage;

export const pageQuery = graphql`
  query CoachPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seo {
          title
          description
        }
        title
        hero {
          pageImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          pageImagePosition
        }
      }
    }
    coachMds: allMarkdownRemark(
      filter: { frontmatter: { mdType: { eq: "coach" } } }
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            name
            avatar {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            title
            position
            yr
            college
            homeTown
            order
          }
        }
      }
    }
  }
`;
